import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { shuffle } from 'client/utils/seed-randomizers';
import { logger } from 'client/utils/isomorphic-logger';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { PageModel } from 'client/data/models/page';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { VisitorModel } from 'client/data/models/visitor';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { handlePublicationState } from 'client/site-modules/shared/utils/publication-states';
import {
  getEmptyCreativeConfig,
  getEmptyCreativeConfigResult,
  getNiceName,
} from 'client/site-modules/shared/components/native-ad/utils/utils';
import { getIncentivesPath, IncentiveModel } from 'client/data/models/incentives';
import { INCENTIVES_AD, SRP_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { isAdEnabledByDma } from 'site-modules/shared/constants/ads';
import { getFilteredIncentives } from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';
import { objectToQueryString } from 'site-modules/shared/utils/string';

const options = { timeout: 1000 };
const AD_UNITS_WITH_OFFERS = [INCENTIVES_AD.AD_NAME, SRP_AD.AD_NAME];
const COMMON_PATH =
  'platform["{platform}"].bt["{bt}"].make["{make}"].model["{model}"].subModel["{subModel}"].type["{type}"].year["{year}"].pubState["{pubState}"]';
const CONFIG_PATH = 'configs.state["{stateCode}"].zip["{zipCode}"].dma["{dma}"]';
const PAGE_PATH = 'pagePath["{pagePath}"]';

/**
 * Full response even if some ads have no configuration
 * adNamesList = [ad1, ad2]
 * res = { ad1: { some values } }
 * result = { ad1: { some values }, ad2: {} }
 * @param adNameList
 * @param res
 * @returns {*}
 */
function responseResult(adNameList, res) {
  const result = adNameList.reduce(
    (configs, adName) => ({
      ...configs,
      [adName]: res[adName] || getEmptyCreativeConfigResult(adName),
    }),
    {}
  );
  return result;
}

async function isSkipExtraParams(adName, context) {
  const adsList = (await context.resolveValue('adsListSkipExtraParams')) || [];
  return adsList.includes(adName);
}

async function getLocationValues(context) {
  const [{ city, stateCode, ipStateCode, zipCode, ipZipCode, dma, ipDma }, location] = await Promise.all([
    context.resolveValue('location', VisitorModel),
    context.resolveValue('location', PageModel),
  ]);
  const adsForcedTestZip = !!get(location, 'query["test-zip"]');

  return adsForcedTestZip
    ? { city, stateCode, zipCode, dma, userZipCode: zipCode }
    : { city, stateCode: ipStateCode, zipCode: ipZipCode, dma: ipDma, userZipCode: zipCode };
}

async function getClientIp(context) {
  const addSiteServedClientIp = await context.resolveValue('addSiteServedClientIp', FeatureFlagModel);
  const clientIp = await context.resolveValue('clientIp', VisitorModel);
  const overrideSiteServedClientIp = await context.resolveValue('overrideSiteServedClientIp', FeatureFlagModel);
  if (overrideSiteServedClientIp) {
    return '127.0.0.1'; // this is needed to avoid true geo-targeting on Kevel Dev Environment
  }
  return addSiteServedClientIp && clientIp ? clientIp : undefined;
}

function getConfigReference({
  platform,
  bt,
  make,
  model,
  subModel,
  type,
  year,
  pubState,
  stateCode,
  zipCode,
  dma,
  adUnitName,
  index,
}) {
  return {
    configRef: {
      $ref: `#/platform/${platform}/bt/${bt}/make/${make}/model/${model}/subModel/${subModel}/type/${type}/year/${year}/pubState/${pubState}/configs/state/${stateCode}/zip/${zipCode}/dma/${dma}/ads/${adUnitName}/${index}`,
    },
  };
}

function getCommonPath(match) {
  const { platform, bt, make, model, subModel, type, year, pubState } = match;
  return `platform["${platform}"].bt["${bt}"].make["${make}"].model["${model}"].subModel["${subModel}"].type["${type}"].year["${year}"].pubState["${pubState}"]`;
}

async function getConfigsPath(match, context) {
  const { stateCode, zipCode, dma } = await getLocationValues(context);
  return `${getCommonPath(match)}.configs.state["${stateCode}"].zip["${zipCode}"].dma["${dma}"].ads`;
}

function configsFilter(configs, callback) {
  return Object.keys(configs)
    .filter(callback)
    .filter(adName => !isEmpty(configs[adName]));
}
/**
 * Resolve additional parameter for incentives ad
 * In the most cases, this logic is important for the server side mode and replaces getting data in the page preloaders.
 * Even if an error occurs here, the data will be received in the component itself
 * @param configs
 * @param vehicle
 * @param context
 * @returns {offers}
 */
async function getIncentivesOffers(context, vehicle, configs) {
  const offerConfigs = configsFilter(configs, adName => AD_UNITS_WITH_OFFERS.includes(adName));
  const offers = await Promise.all(
    offerConfigs.map(async adName => {
      if ([INCENTIVES_AD.AD_NAME].includes(adName) && (await isSkipExtraParams(INCENTIVES_AD.AD_NAME, context))) {
        return { adName, offersNumber: 0 };
      }

      const { creativeConfigData } = configs[adName][0];
      if (creativeConfigData.headline) {
        return { adName, offersNumber: 0 };
      }

      const styleId = get(creativeConfigData, 'vehicleInfo.submodels[0].styleId');
      if (!styleId) {
        logger('warn', `${adName}: vehicleInfo or styleId is empty`);
        return { adName, offersNumber: 0 };
      }

      const incentives = await context.resolveValue(getIncentivesPath(styleId), IncentiveModel);
      const filteredIncentives = getFilteredIncentives(incentives);
      const offersNumber = filteredIncentives.length;

      return { adName, offersNumber };
    })
  );
  return offers;
}

/**
 * Resolve additional parameter for ads
 * @param context
 * @param match
 * @param adNames
 * @param configs
 * @returns {}
 */
export async function resolveAdditionalParams(context, match, adNames, configs) {
  const { model, pagePath } = match;

  // sometimes, e.g. srp make page, model name is not defined in the vehicle
  // in fact, vehicle is only used to resolve additional params
  // we should remove any references to vehicle page since it will work incorrect on the pages such as comparator
  // we cannot use match parameters because they don't contain nice name values
  let vehicle = await context.resolveValue('vehicle', PageModel);
  if (!get(vehicle, 'model.niceName')) {
    vehicle = {
      ...vehicle,
      model: {
        niceName: model,
      },
    };
  }

  // ================= IMPORTANT!!! ====================
  // Every promise function should return array, except getLocationValues function
  // The function should have an implementation similar to resolveIncentivesOffers function
  // ===================================================
  const [{ city, stateCode, zipCode, dma, userZipCode }, offers] = await Promise.all(
    [getLocationValues(context), getIncentivesOffers(context, vehicle, configs)].map(promise =>
      promise
        .then(value => value)
        .catch(err => {
          if (err.status === 408) {
            // PLAT-2354: stringifying an error object via logger('error', `resolveIncentivesOffers: ${err}`) will
            // loose the err.status field from the log which the pipeline depends on to avoid declaring canary a failure.
            // passing the error object to the logger on other hand will work. and logging it as a warn instead of error
            // cause that is platform's default behavior.
            logger('warn', err);
          } else {
            logger('error', `resolveAdditionalParams: ${err}`);
          }
          return [];
        })
    )
  );

  const verified = [];
  const result = Object.entries(configs).reduce((acc, [adUnitName, adConfigs]) => {
    try {
      let offersNumber;
      if (AD_UNITS_WITH_OFFERS.includes(adUnitName)) {
        offersNumber = offers.find(({ adName }) => adName === adUnitName);
      }

      acc[adUnitName] = adConfigs
        .filter(v => v)
        .map((config, index) => ({
          ...getConfigReference({ ...match, stateCode, zipCode, dma, adUnitName, index }),
          ...getEmptyCreativeConfig(adUnitName),
          ...offersNumber,
          zipCode: userZipCode,
          ipZipCode: zipCode,
          pagePath: `${userZipCode}:${pagePath}`,
          dma,
          location: {
            city,
            stateCode,
          },
          shuffleFeatures: shuffle(get(config, 'creativeConfigData.features', [])).slice(0, 3),
        }));
      // DON'T DELETE, e2e tests USE it
      verified.push(adUnitName);
    } catch (err) {
      logger('error', `resolveAdditionalParams for ${adUnitName}: ${err}`);
    }
    return acc;
  }, {});

  await context.updateValue('pageAdsListVerified', verified);

  return responseResult(adNames, result);
}

async function isSkipRequest(match, context) {
  const { make, model, type, bt } = match;
  // vehicle make or model or type or bt should be defined
  if (make === 'none' && model === 'none' && type === 'none' && bt === 'none') {
    return true;
  }

  const isSiteServedAdsEnabled = await context.resolveValue('siteServedAds', FeatureFlagModel);
  const location = await context.resolveValue('location', VisitorModel);
  return !isSiteServedAdsEnabled || !isAdEnabledByDma(location);
}

async function requestApi(match, context, adNames) {
  const { platform, make, model, subModel, type, year, pubState, stateCode, zipCode, dma } = match;
  const [pageName, pageNameLegacy, utmAds, addSiteServedEdwPg, addSiteServedUtms, visitorid, ip] = await Promise.all([
    context.resolveValue('page.name', PageModel),
    context.resolveValue('legacy.pageName', PageModel),
    context.resolveValue('ads.utmAds', PageModel),
    context.resolveValue('addSiteServedEdwPg', FeatureFlagModel),
    context.resolveValue('addSiteServedUtms', FeatureFlagModel),
    context.resolveValue('id', VisitorModel),
    getClientIp(context),
  ]);
  const currentPageName = pageNameLegacy || pageName;
  const utms = utmAds?.utms;

  const params = {
    make: getNiceName(make),
    model: getNiceName(model),
    submodel: getNiceName(subModel),
    type: currentPageName && currentPageName.includes('model_car_inventory_vin_detail') ? '' : getNiceName(type),
    year: getNiceName(year),
    dma,
    state: stateCode,
    zip: zipCode,
    platform,
    creative: adNames.join(','),
    edwpg: addSiteServedEdwPg && currentPageName ? currentPageName : '',
    utms: addSiteServedUtms && utms ? utms : '',
    usein: handlePublicationState(pubState),
    visitorid,
    ip,
  };
  const url = `/site-served-creative/v2/find?${objectToQueryString(params)}`;
  return withMetrics(EdmundsAPI, context).fetchJson(url, options);
}

/**
 * Creative Config Data for native ads
 */
export const NativeAdsCreativeConfigModel = createModelSegment('nativeAdsCreativeConfig', [
  /**
   *  pageAdsList: [
   *    'incentives-native-ad',
   *    'build-and-price-native-ad'
   *  ]
   *  This path should be set in the page definition to preload ads
   *
   *  TODO: if ad unit is migrated to v2, developer only needs to change ad name to ${name}-v2 in the file
   *  TODO: client/site-modules/shared/components/native-ad/utils/constants.js
   *  TODO: after migration of all ad units, you need to revert this code to the previous state
   */
  {
    path: 'pageAdsList',
    async update(value) {
      return Promise.resolve(Array.from(value));
    },
  },
  {
    path: 'pageAdsListSeparatedCalls',
    async update(value) {
      return Promise.resolve(Array.from(value));
    },
  },
  /**
   * adsListSkipExtraParams: [
   *   'incentives-native-ad',
   *   'build-and-price-native-ad'
   * ]
   * This path should be set in the page definition to preload ads
   */
  {
    path: 'adsListSkipExtraParams',
    update(value) {
      return Promise.resolve(Array.from(value));
    },
  },
  {
    path: 'pageAdsListVerified',
    update(value, match, context) {
      return context
        .resolveValue('pageAdsListVerified')
        .then(current => Array.from(new Set((current || []).concat(value))));
    },
  },

  /**
   * Return site served ads configs for given list of ads, pageAdsList
   */
  {
    path: `${COMMON_PATH}.${CONFIG_PATH}.ads`,
    async resolve(match, context) {
      const adsList = await context.resolveValue('pageAdsList', NativeAdsCreativeConfigModel, false);
      return requestApi(match, context, adsList).then(result =>
        adsList.reduce((acc, adName) => {
          acc[adName] = get(result, adName, []);
          return acc;
        }, {})
      );
    },
  },
  /**
   * Return site served ads config for given ads, adName
   * @see getCreativeConfigAdPathByVehicle
   */
  {
    path: `${COMMON_PATH}.${CONFIG_PATH}.ads["{adName}"]`,
    async resolve(match, context) {
      const { adName } = match;
      return requestApi(match, context, [adName]).then(result => get(result, adName, []));
    },
  },
  /**
   * Return site served ads configs for given list of ads, pageAdsList
   * also, resolve additional parameters such as offer numbers for incentive ad unit
   * @see getCreativeConfigAdPathByVehicle
   */
  {
    path: `${COMMON_PATH}.${PAGE_PATH}.ads`,
    async resolve(match, context) {
      if (await isSkipRequest(match, context)) {
        return context.abort();
      }
      const [adsList, configsPath] = await Promise.all([
        context.resolveValue('pageAdsList', NativeAdsCreativeConfigModel, false),
        getConfigsPath(match, context),
      ]);

      if (isEmpty(adsList)) {
        await context.updateValue('pageAdsList', []);
        return context.abort();
      }
      return context
        .resolveValue(configsPath, NativeAdsCreativeConfigModel)
        .then(configs => resolveAdditionalParams(context, match, adsList, configs))
        .catch(() => responseResult(adsList, {}));
    },
  },
  /**
   * Return site served ads config for given ads, adName
   * also, resolve additional parameters such as offer numbers for incentive ad unit
   * @see getCreativeConfigAdPathByVehicle
   */
  {
    path: `${COMMON_PATH}.${PAGE_PATH}.ads["{adName}"]`,
    async resolve(match, context) {
      if (await isSkipRequest(match, context)) {
        return context.abort();
      }

      const { pagePath, adName } = match;
      const [adsList, siteServedSeparatedCalls] = await Promise.all([
        context.resolveValue('pageAdsList'),
        context.resolveValue('siteServedSeparatedCalls', FeatureFlagModel),
      ]);

      // wait for adsList to be defined
      if (!adsList && !siteServedSeparatedCalls) {
        return {};
      }

      // resolve ad units configs from global ads list
      if (adsList?.includes(adName)) {
        const configs = await context.resolveValue(
          `${getCommonPath(match)}.pagePath["${pagePath}"].ads`,
          NativeAdsCreativeConfigModel
        );
        return configs[adName];
      }

      // resolve single ad unit config
      const configsPath = await getConfigsPath(match, context);
      return context
        .resolveValue(`${configsPath}["${adName}"]`, NativeAdsCreativeConfigModel)
        .then(config => resolveAdditionalParams(context, match, [adName], { [adName]: config }))
        .then(result => result[adName])
        .catch(() => getEmptyCreativeConfigResult(adName));
    },
  },
]);

const configDataCommon = {
  name: PropTypes.string,
  excluded: PropTypes.bool,
  siteServedAdsUpdater: PropTypes.number,
  zipCode: PropTypes.string,
  ipZipCode: PropTypes.string,
};

const creativeConfigDataCommon = {
  isConquest: PropTypes.bool,
  headline: PropTypes.string,
  logo: PropTypes.string,
  photo: PropTypes.string,
  photoDisclaimer: PropTypes.string,
  cta: PropTypes.string,
  linkDisplayUrl: PropTypes.string,
  disclaimerCopy: PropTypes.string,
  make: PropTypes.string,
  msrp: PropTypes.string,
  vehicleInfo: PropTypes.shape({}),
  trimNames: PropTypes.arrayOf(PropTypes.string),
  lineItemId: PropTypes.string,
  lineItemType: PropTypes.string,
  lineItemEndDate: PropTypes.string,
  productSku: PropTypes.string,
  siteServedCreativeId: PropTypes.string,
  target: PropTypes.string,
};

const traffickingData = PropTypes.shape({
  clickTracker: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  adzerkImpressionUrl: PropTypes.string,
  adzerkClickUrl: PropTypes.string,
  impressionTrackers: PropTypes.shape({
    thirdParty: PropTypes.string,
  }),
});

export const PricingCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
    imgDisclaimer: PropTypes.string,
    headline2: PropTypes.string,
  }),
  traffickingData,
});

export const PricingCreativeConfigDefaultProps = null;

export const InsightCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
  }),
  traffickingData,
});

export const InsightCreativeConfigDefaultProps = null;

export const SRPCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
    subheadline: PropTypes.string,
    body: PropTypes.string,
  }),
  traffickingData,
  offersNumber: PropTypes.number,
});

export const SRPCreativeConfigDefaultProps = null;

export const IncentivesCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
    headline2: PropTypes.string,
  }),
  traffickingData,
  offersNumber: PropTypes.number,
});

export const IncentivesCreativeConfigDefaultProps = null;

export const BuildPriceCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
  }),
  traffickingData,
});

export const BuildPriceCreativeConfigDefaultProps = null;

export const PhotoflipperCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
    headline2: PropTypes.string,
  }),
  traffickingData,
  offersNumber: PropTypes.number,
});

export const PhotoflipperCreativeConfigDefaultProps = null;

export const SegmentListingCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
    accolades: PropTypes.arrayOf(PropTypes.string),
    bulletText: PropTypes.string,
  }),
  traffickingData,
  offersNumber: PropTypes.number,
});

export const SegmentListingCreativeConfigDefaultProps = null;

export const FilmstripCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
    bulletText: PropTypes.string,
  }),
  traffickingData,
  offersNumber: PropTypes.number,
});

export const FilmstripCreativeConfigDefaultProps = null;

export const EasListingCreativeConfigDefaultProps = null;

export const EasListingCreativeConfigPropTypes = PropTypes.shape({
  ...configDataCommon,
  creativeConfigData: PropTypes.shape({
    ...creativeConfigDataCommon,
  }),
  traffickingData,
});
